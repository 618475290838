<template>
  <tr :class="getClass">
    <td
      v-for="(header, index) in headers"
      :key="index"
    >
      <div :class="isDraggable && !index ? 'd-flex' : ''">
        <div
          v-if="isDraggable && !index"
          class="drag-handle"
        >
          <v-icon>mdi-drag</v-icon>
        </div>
        <slot
          :item="item"
          :name="columnName(header)"
        >
          <div :style="getAlignment(header)">
            {{ getNonSlotValue(item, header) }}
          </div>
        </slot>
      </div>
    </td>
  </tr>
</template>
  
  <script>
  export default {
    name: "DataTableRowHandler",
    components: {},
    props: {
      itemClass: {
        type: String,
        default: "",
      },
      item: {
        type: Object,
        default: () => {
          return {};
        },
      },
      headers: {
        type: Array,
        default: () => {
          return [];
        },
      },
      isDraggable: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {};
    },
    computed: {
      getClass() {
        return this.itemClass;
      }
    },
    methods: {
      columnName(header) {
        return `item.${header.value}`;
      },
      getAlignment(header) {
        const align = header.align ? header.align : "right";
        return `text-align: ${align}`;
      },
      getNonSlotValue(item, header) {
        const val = item[header.value];
  
        if (val) {
          return val;
        }
  
        return "";
      },
    },
  };
  </script>
  <style>
  .drag-handle {
    cursor: move;
  }
  </style>