<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-template-shadow -->
<!-- eslint-disable vue/no-lone-template -->
<template>
  <div>
    <material-card
      color="general"
      :title="$t('Delivery.title')"
      :text="$t('Delivery.subtitle')"
    >
      <v-dialog
        v-model="dialog"
        max-width="500px"
      >
        <template #activator="{ on }">
          <v-btn
            color="zwonogreen"
            dark
            class="mb-2 z-btn-top-right"
            v-on="on"
          >
            <v-icon left>
              mdi-plus
            </v-icon>
            {{ $t("Common.new_item") }}
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5 z-card-title">
            <v-icon left>
              mdi-gift-open-outline
            </v-icon>
            {{ $t("Delivery.delivery_details") }}
            <v-spacer />
            <v-btn
              icon
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form
              ref="editDeliveryForm"
              v-model="editedItem.isValid"
            >
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex>
                    <v-menu
                      v-model="menuDatePicker"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItemFormatedDeliveryDate"
                          :label="$t('Delivery.delivery_date')"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          :rules="[rules.required]"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="editedItem.delivery_date"
                        :dark="true"
                        :min="new Date().toISOString().substr(0, 10)"
                        @input="menuDatePicker = false"
                      />
                    </v-menu>
                    <v-select
                      v-model="editedItem.driver_ids"
                      :items="sortedDriverList"
                      item-value="id"
                      :label="$t('Driver.driver_names')"
                      multiple
                      :rules="[rules.required]"
                    >
                      <template #selection="data">
                        <span>{{ data.item.user.fullName }} ({{ data.item.user.email }})</span>
                      </template>
                      <template #item="data">
                        <span>{{ data.item.user.fullName }} ({{ data.item.user.email }})</span>
                      </template>
                    </v-select>
                    <!-- <v-select
                      v-model="editedItem.vehicle_id"
                      :items="sortedVehicleList"
                      item-text="vehicle_registration"
                      item-value="id"
                      :label="$t('Vehicle.vehicle_registration')"
                      :rules="[rules.required]"
                    /> -->
                    <v-subheader class="delivery-custom-subheader">
                      {{ $t("Common.timeslots") }}
                    </v-subheader>
                    <v-btn
                      color="zwonogreen"
                      :disabled="editedItem.status != '' && editedItem.status != 'NotDelivered'"
                      @click="selectAllTimeslots"
                    >
                      <v-icon> mdi-beaker-check </v-icon>
                    </v-btn>
                    <div
                      v-for="ts in timeslotList"
                      :key="ts.id"
                    >
                      <v-checkbox
                        v-model="editedItem.timeslots"
                        :disabled="editedItem.status != '' && editedItem.status != 'NotDelivered'"
                        :label="ts.start + '-' + ts.end"
                        :value="ts.id"
                      />
                    </div>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-text v-if="alertTextEdit">
            <v-alert
              dense
              type="error"
            >
              {{ alertTextEdit }}
            </v-alert>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn
              color="zwonogray"
              class="z-hover-red-bg-color pr-5"
              @click="close"
            >
              <v-icon left>
                mdi-cancel
              </v-icon>
              {{ $t("Common.cancel") }}
            </v-btn>
            <v-btn
              color="zwonogreen"
              class="pr-5"
              :loading="isSaving"
              :disabled="!editedItem.isValid"
              @click="save"
            >
              <v-icon left>
                mdi-check
              </v-icon>
              {{ $t("Common.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogMapOrders"
        max-width="1600px"
      >
        <template>
          <v-card class="z-main">
            <v-card-title class="text-h5 z-card-title">
              <v-icon left>
                mdi-gift-open-outline
              </v-icon>
              {{ $t("Delivery.manage_delivery_orders") }}
              <v-spacer />
              <v-btn
                icon
                @click="dialogMapOrders = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('Common.search')"
                single-line
                hide-details
                class="mb-4"
              />
              <span>
                {{ `${$t("Delivery.selected_orders_count")}: ${mapOrdersItem.orders.length}` }}
              </span>
              <v-data-table
                v-model="mapOrdersItem.orders"
                :loading-text="$t('Common.loading-text')"
                dense
                :headers="headersMapOrders"
                :items="getAllOrdersForDelivery"
                item-key="id"
                class="elevation-1"
                show-select
                mobile-breakpoint="1110"
                :search="search"
              >
                <template #[`item.order_date`]="{ item }">
                  {{ formatDate(item.order_date) }}
                </template>
                <!--<template #[`item.full_alternative_address`]="{ item }">
                  <a
                    v-if="item.alternative_address"
                    @click="openAlternateAddressModal(item)"
                  >
                    {{ callMapFullAlternativeAddress(item.alternative_address) }}
                  </a>
                  <v-icon
                    v-else
                    @click="openAlternateAddressModal(item)"
                  >
                    mdi-plus
                  </v-icon>
                </template>
                <template #[`item.is_user_change`]="{ item }">
                  {{ item.alternative_address?.is_user_change ? $t("Common.yes") : $t("Common.no") }}
                </template>-->
                <template #[`item.customer.is_geocoded`]="{ item }">
                  <td
                    v-if="
                      item.customer.is_geocoded === undefined ||
                        item.customer.is_geocoded === null ||
                        item.customer.is_geocoded === false
                    "
                  >
                    <v-icon color="red">
                      mdi-alert-octagon
                    </v-icon>
                  </td>
                  <td v-else>
                    <v-icon color="green">
                      mdi-check-all
                    </v-icon>
                  </td>
                </template>
                <template #[`item.actions`]="{ item }">
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-icon
                        medium
                        class="z-hover-green-color"
                        color="blue"
                        v-bind="attrs"
                        @click="getOrderItems(item)"
                        v-on="on"
                      >
                        apps
                      </v-icon>
                    </template>
                    <span>{{ $t("Common.tooltips.details") }}</span>
                  </v-tooltip>
                </template>
              </v-data-table>
              <p>
                <v-file-input
                  show-size
                  :label="$t('Common.csv_import')"
                  prepend-icon="mdi-file-excel"
                  accept="text/csv"
                  @change="mapFromCsv"
                />
              </p>
              <p
                v-if="mapFromCsvNotMapped.length > 0"
                color="red"
              >
                <v-text-field
                  :value="mapFromCsvNotMapped"
                  label="Not mapped"
                  disabled
                />
              </p>
            </v-card-text>
          </v-card>
        </template>
        <v-card>
          <v-card-text 
            v-if="alertTextMapOrders"
            class="d-flex justify-center"
          >
            <v-alert
              class="mb-0 mt-4"
              dense
              type="error"
            >
              {{ alertTextMapOrders }}
            </v-alert>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn
              color="zwonogray"
              class="z-hover-red-bg-color pr-5"
              :loading="isLoadingButtons"
              @click="closeMapOrders"
            >
              <v-icon left>
                mdi-cancel
              </v-icon>
              {{ $t("Common.cancel") }}
            </v-btn>
            <v-btn
              v-if="!selectedDelivery?.is_dispatched && selectedDelivery?.status === 'NotDelivered'"
              color="blue darken-1"
              class="z-hover-red-bg-color pr-5"
              :loading="isLoadingButtons"
              :disabled="!mapOrdersItem?.orders?.length"
              @click="openMapPreview"
            >
              <v-icon left>
                mdi-map
              </v-icon>
              {{ $t("Common.preview") }}
            </v-btn>
            <v-btn
              v-if="!selectedDelivery?.is_dispatched && selectedDelivery?.status === 'NotDelivered'"
              color="zwonogreen"
              class="pr-5"
              :loading="isLoadingButtons"
              @click="saveMapOrders(true, true, true)"
            >
              <v-icon left>
                mdi-check
              </v-icon>
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-if="dialogShowRoute"
        v-model="dialogShowRoute"
        max-width="1600px"
      >
        <template>
          <v-card>
            <div
              v-if="isDialogShowRouteSpinnerActive"
              class="table-spinner-container"
            >
              <v-progress-circular
                color="primary"
                indeterminate
                :size="100"
                :width="8"
              />
            </div>
            <template v-else>
              <v-card-title class="text-h5 z-card-title">
                <v-icon left>
                  mdi-truck-fast-outline
                </v-icon>
                {{ $t("Delivery.delivery_route_map") }}
                <v-spacer />
                <v-btn
                  icon
                  @click="dialogShowRoute = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-subtitle class="mt-4 mt-md-5">
                <MapLibre :route="route" />
              </v-card-subtitle>
              <v-card-text class="z-main">
                <v-data-table
                  v-if="selectedDelivery?.status === 'Planned' || selectedDelivery?.status === 'NotDelivered'"
                  :loading-text="$t('Common.loading-text')"
                  dense
                  :headers="headersRouteOrders"
                  :items="routeOrders"
                  item-key="id"
                  class="elevation-1 small-header-font"
                  hide-default-footer
                  mobile-breakpoint="0"
                  disable-pagination
                >
                  <template #body="props">
                    <draggable
                      :list="props.items"
                      tag="tbody"
                      :disabled="selectedDelivery?.status === 'Planned' ? false : true"
                      handle=".drag-handle"
                      @start="dragStart"
                      @end="dragEnd"
                    >
                      <data-table-row-handler
                        v-for="(item, index) in props.items"
                        :key="index"
                        :item="item"
                        :headers="headersRouteOrders"
                        :is-draggable="selectedDelivery?.status === 'Planned'"
                      >
                        <template #[`item.nr`]="{ item }">
                          {{ item.order_number_in_delivery ? item.nr : '-' }}
                          {{ isTimestampChanged(item) ? ' *' : '' }}
                        </template>
                        <template #[`item.timeslot`]="{ item }">
                          <v-select
                            v-if="!selectedDelivery?.is_dispatched && selectedDelivery?.status === 'NotDelivered'"
                            v-model="item.timeslot"
                            :items="timeslotList"
                            :item-text="buildTimestamp"
                            density="compact"
                            hide-details
                            :clearable="isTimestampChanged(item)"
                            persistent-clear
                            @click:clear="clickClearTimestampChange(item.id)"
                            @input="(selectedTimeslot) => changeTimeslot(selectedTimeslot, item)"
                          />
                          <span
                            v-else
                          >
                            {{ item.timeslot }}
                          </span>
                        </template>
                        <template #[`item.address`]="{ item }">
                          <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                              <span
                                class="two-lines-ellipsis"
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{ item.address }}
                              </span>
                            </template>
                            <span>{{ item.address }}</span>
                          </v-tooltip>
                        </template>
                        <template #[`item.full_alternative_address`]="{ item }">
                          <v-tooltip
                            v-if="item.alternative_address" 
                            bottom
                          >
                            <template #activator="{ on, attrs }">
                              <a
                                class="two-lines-ellipsis"
                                v-bind="attrs"
                                v-on="on"
                                @click="openAlternateAddressModal(item)"
                              >
                                {{ callMapFullAlternativeAddress(item.alternative_address) }}
                              </a>
                            </template>
                            <span>{{ callMapFullAlternativeAddress(item.alternative_address) }}</span>
                          </v-tooltip>
                          <v-icon
                            v-else
                            @click="openAlternateAddressModal(item)"
                          >
                            mdi-plus
                          </v-icon>
                        </template>
                        <template #[`item.is_user_change`]="{ item }">
                          {{ item.alternative_address ? (item.alternative_address.is_user_change ? $t("Common.yes") : $t("Common.no")) : '-' }}
                        </template>
                        <template #[`item.phone`]="{ item }">
                          <span>{{ item.phone }}</span>
                        </template>
                        <template #[`item.email`]="{ item }">
                          <span
                            v-html="insertWbr(item.email)"
                          />
                        </template>
                        <template #[`item.status`]="{ item }">
                          <div>
                            <div class="d-inline-block status-max-w">
                              <v-select
                                v-if="!selectedDelivery?.is_dispatched && (selectedDelivery?.status === 'Planned' || selectedDelivery?.status === 'NotDelivered')"
                                v-model="item.confirmation_status"
                                :items="endclientStatusItems"
                                item-value="value"
                                item-text="label"
                                class="pt-0 mt-0"
                                density="compact"
                                hide-details
                                @input="changeEndclientStatus(item)"
                              />
                              <span
                                v-else
                                class="mr-2"
                              >
                                {{ item.confirmation_status }}
                              </span>
                              <v-icon
                                v-if="
                                  item.confirmation_status === undefined ||
                                    item.confirmation_status === null ||
                                    item.confirmation_status === 'Unknown'
                                "
                                medium
                                class="mr-2"
                                color="orange"
                              >
                                mdi-help-circle
                              </v-icon>
                              <v-icon
                                v-if="item.confirmation_status === 'Confirmed'"
                                medium
                                class="mr-2"
                                color="green"
                              >
                                mdi-check
                              </v-icon>
                              <v-icon
                                v-if="item.confirmation_status === 'Declined'"
                                medium
                                class="mr-2"
                                color="red"
                              >
                                mdi-alert-octagon-outline
                              </v-icon>
                            </div>
                          </div>
                        </template>
                        <template #[`item.is_first_to_deliver`]="{ item }">
                          <v-checkbox 
                            :disabled="selectedDelivery?.is_dispatched || selectedDelivery?.status !== 'NotDelivered'"
                            :input-value="selectedFirstOrderToDeliver === item.id"
                            :class="{ 'primary-checkbox': selectedFirstOrderToDeliver === item.id }"
                            @click.prevent="selectFirstToDeliver(item)"
                          />
                        </template>
                        <template #[`item.was_reminded`]="{ item }">
                          <span
                            v-if="item.confirmation_status === 'Confirmed' || item.confirmation_status === 'Declined'"
                          >
                            -
                          </span>
                          <v-icon
                            v-else-if="item.was_reminded"
                            color="success"
                            size="x-large"
                          >
                            mdi-check
                          </v-icon>
                          <v-icon
                            v-else
                            color="warning"
                            size="x-large"
                          >
                            mdi-close
                          </v-icon>
                        </template>
                        <template #[`item.endclient_message`]="{ item }">
                          <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                              <span
                                class="two-lines-ellipsis"
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{ item.endclient_message }}
                              </span>
                            </template>
                            <span>{{ item.endclient_message }}</span>
                          </v-tooltip>
                        </template>
                        <template #[`item.actions`]="{ item }">
                          <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                              <v-icon
                                medium
                                class="z-hover-green-color mr-2"
                                color="blue"
                                v-bind="attrs"
                                @click="getOrderItems(item)"
                                v-on="on"
                              >
                                apps
                              </v-icon>
                            </template>
                            <span>{{ $t("Common.tooltips.details") }}</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                              <v-icon
                                medium
                                class="z-hover-green-color"
                                color="red"
                                v-bind="attrs"
                                @click="showClearDeliveryDataModal(item)"
                                v-on="on"
                              >
                                mdi-blur-off
                              </v-icon>
                            </template>
                            <span>{{ $t("Common.tooltips.remove-from-delivery") }}</span>
                          </v-tooltip>
                        </template>
                      </data-table-row-handler>
                    </draggable>
                  </template>
                </v-data-table>
                <v-data-table
                  v-if="selectedDelivery?.status === 'Delivered'"
                  :loading-text="$t('Common.loading-text')"
                  dense
                  :headers="headersRouteOrdersDelivered"
                  :items="routeOrders"
                  item-key="id"
                  class="elevation-1"
                  hide-default-footer
                  disable-pagination
                >
                  <template #[`item.status`]="{ item }">
                    <v-icon
                      v-if="
                        item.status === undefined ||
                          item.status === null ||
                          item.status === 'InProgress' ||
                          item.status === 'Ordered'
                      "
                      medium
                      class="mr-2"
                      color="brown"
                    >
                      mdi-help-circle
                    </v-icon>
                    <v-icon
                      v-if="item.status === 'Delivered'"
                      medium
                      class="mr-2"
                      color="green"
                    >
                      mdi-check
                    </v-icon>
                    <v-icon
                      v-if="item.status === 'NotDelivered'"
                      medium
                      class="mr-2"
                      color="red"
                    >
                      mdi-alert-octagon-outline
                    </v-icon>
                  </template>

                  <template #[`item.is_paid`]="{ item }">
                    <v-icon
                      v-if="item.is_paid === true"
                      medium
                      class="mr-2"
                      color="green"
                    >
                      mdi-check
                    </v-icon>
                    <v-icon
                      v-if="item.is_paid === false"
                      medium
                      class="mr-2"
                      color="red"
                    >
                      mdi-alert-octagon-outline
                    </v-icon>
                  </template>
                  <template #[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-icon
                          medium
                          class="z-hover-green-color"
                          color="blue"
                          v-bind="attrs"
                          @click="getOrderItems(item)"
                          v-on="on"
                        >
                          apps
                        </v-icon>
                      </template>
                      <span>{{ $t("Common.tooltips.details") }}</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-icon
                          medium
                          class="z-hover-green-color"
                          color="red"
                          v-bind="attrs"
                          @click="showClearDeliveryDataModal(item)"
                          v-on="on"
                        >
                          mdi-blur-off
                        </v-icon>
                      </template>
                      <span>{{ $t("Common.tooltips.remove-from-delivery") }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <br>
                <v-alert
                  v-if="selectedDelivery.status !== 'Delivered' && (selectedDelivery?.is_dispatched || dispatchToWarehouseAlertText)"
                  dense
                  :type="dispatchToWarehouseAlertType"
                >
                  {{ selectedDelivery?.is_dispatched ? $t("Delivery.dispatch_success") : dispatchToWarehouseAlertText }}
                </v-alert>
                <v-alert
                  v-else-if="selectedDelivery.status === 'Delivered' && (selectedDelivery?.is_reported || generateDeliveryReportText)"
                  dense
                  :type="generateDeliveryReportType"
                >
                  {{ selectedDelivery?.is_reported ? $t("Delivery.generate_delivery_report_success") : generateDeliveryReportText }}
                </v-alert>
                <p>
                  <center>
                    <v-btn
                      v-if="!selectedDelivery?.is_dispatched && selectedDelivery?.status === 'NotDelivered'"
                      color="blue darken-1 mr-5"
                      large
                      @click="recalculateDeliveryRoute()"
                    >
                      {{ $t("Delivery.recalculate") }}
                    </v-btn>
                    <v-btn
                      v-if="!selectedDelivery?.is_dispatched && selectedDelivery?.status === 'NotDelivered'"
                      color="primary mr-5"
                      large
                      @click="dialogShowRoute = false"
                    >
                      {{ $t("Delivery.back_to_manage_delivery_orders") }}
                    </v-btn>
                    <v-btn
                      v-if="selectedDelivery?.status === 'Planned' && !selectedDelivery?.is_dispatched"
                      color="blue"
                      large
                      class="z-fw-500 z-ico-fs-150 z-mw-200 mr-5"
                      :loading="isDispatching"
                      @click="onRemindEndclients"
                    >
                      <v-icon left>
                        mdi-cellphone-text
                      </v-icon>
                      {{ $t("Delivery.remind_endclients") }}
                    </v-btn>
                    <v-btn
                      v-if="selectedDelivery?.status === 'Planned'"
                      color="zwonogreen"
                      large
                      class="z-fw-500 z-ico-fs-150 z-mw-200 mr-5"
                      :loading="isDispatching"
                      @click="onDispatchToWarehouse"
                    >
                      <v-icon left>
                        mdi-truck-delivery-outline
                      </v-icon>
                      {{ $t("Delivery.dispatch") }}
                    </v-btn>
                    <v-btn
                      v-if="selectedDelivery?.status === 'Planned'"
                      color="grey"
                      large
                      class="z-fw-500 z-ico-fs-150 z-mw-200 mr-5"
                      :loading="isDispatching"
                      @click="onDownloadDispatchPdf"
                    >
                      {{ $t("Delivery.download") }}
                    </v-btn>
                    <v-btn
                      v-else-if="selectedDelivery?.status === 'Delivered'"
                      color="zwonogreen"
                      large
                      class="z-fw-500 z-ico-fs-150 z-mw-200"
                      :loading="isGeneratingReport"
                      @click="onGenerateDeliveryReport"
                    >
                      <v-icon left>
                        mdi-truck-delivery-outline
                      </v-icon>
                      {{ $t("Delivery.generate_delivery_report") }}
                    </v-btn>
                  </center>
                </p>
              </v-card-text>
            </template>
          </v-card>
        </template>
      </v-dialog>
      <!-- Display order items dialog -->
      <v-dialog
        v-model="dialogOrderItems"
        max-width="500px"
      >
        <v-card class="z-main">
          <v-card-title class="text-h5 z-card-title">
            <v-icon left>
              mdi-bookmark-outline
            </v-icon>
            {{ $t("Delivery.ordered_items") }}
            <v-spacer />
            <v-btn
              icon
              @click="dialogOrderItems = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex>
                  <v-text-field
                    v-if="orderDetails.customer"
                    v-model="orderDetails.customer.customer_name"
                    :label="$t('Common.customer_name')"
                    :disabled="true"
                  />
                  <v-text-field
                    v-if="orderDetails.customer"
                    v-model="orderDetails.full_address"
                    :label="$t('Common.address')"
                    :disabled="true"
                  />
                  <v-text-field
                    v-if="orderDetails.customer"
                    v-model="orderDetails.customer.phone_number"
                    :label="$t('Common.phone_number')"
                    :disabled="true"
                  />
                  <v-text-field
                    v-if="orderDetails.customer"
                    v-model="orderDetails.customer.email"
                    :label="$t('Common.email')"
                    :disabled="true"
                  />
                  <v-text-field
                    v-model="orderDetails.order_date_formatted"
                    :label="$t('Order.order_date')"
                    :disabled="true"
                  />
                  <v-text-field
                    v-model="orderDetails.description"
                    :label="$t('Common.description')"
                    :disabled="true"
                  />
                  <v-text-field
                    v-model="orderDetails.note"
                    :label="$t('Common.note')"
                    :disabled="true"
                  />
                  <v-text-field
                    v-model="orderDetails.price"
                    :label="$t('Common.price')"
                    :disabled="true"
                  />
                </v-flex>
              </v-layout>
            </v-container>
            <v-data-table
              :loading-text="$t('Common.loading-text')"
              :headers="orderDetailItems"
              :items="orderDetails.items"
              class="elevation-1"
            >
              <template slot="body.append">
                <tr>
                  <th>{{ $t("Common.total") }}</th>
                  <th />
                  <th />
                  <th>{{ orderDetails.price }}</th>
                </tr>
              </template>
              <template #[`item.total`]="{ item }">
                {{ item.qty * item.price }}
              </template>
            </v-data-table>
          </v-card-text>
          <div
            ref="map-root"
            style="width: 100%; height: 100%"
          >
            <p />
          </div>

          <v-card-actions class="d-flex justify-center">
            <v-btn
              color="zwonogray"
              class="z-hover-red-bg-color pr-5"
              @click="closeOrderItems"
            >
              <v-icon left>
                mdi-close
              </v-icon>
              {{ $t("Common.close") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- END Display order items dialog -->
      <div>
        <v-data-table
          :loading-text="$t('Common.loading-text')"
          :headers="headers"
          :items="deliveryList"
          :options.sync="paginationOptions"
          :server-items-length="(deliveryListMeta?.itemCount) ? deliveryListMeta.itemCount : 0"
          :loading="paginationLoading"
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50]
          }"
          class="elevation-1"
        >
          <template
            slot="headerCell"
            slot-scope="{ header }"
          >
            <span
              class="subheading font-weight-light text-general text--darken-3"
              v-text="header.text"
            />
          </template>
          <template #[`item.delivery_date`]="{ item }">
            {{ formatDate(item.delivery_date) }}
          </template>
          <template #[`item.status`]="{ item }">
            <td>
              <div class="d-inline-block status-max-w">
                <v-select
                  v-if="role === 'SUPER_ADMIN'"
                  v-model="item.status"
                  :items="deliveryStatusItems"
                  item-value="value"
                  item-text="label"
                  class="pt-0 mt-0"
                  density="compact"
                  hide-details
                  @change="changeDeliveryStatus(item)"
                />
                <span v-else>
                  {{ item.status }}
                </span>
              </div>
            </td>
          </template>
          <template #[`item.warehouse_status`]="{ item }">
            <template v-if="item.status === 'Delivered' ">
              <span v-if="item.is_reported">{{ $t("Common.reported") }}</span>
              <span v-else>{{ $t("Common.not_reported") }}</span>
            </template>
            <template v-else>
              <span v-if="item.is_dispatched">{{ $t("Common.dispatched") }}</span>
              <span v-else>{{ $t("Common.not_dispatched") }}</span>
            </template>
          </template>
          <template #[`item.in_progress_date`]="{ item }">
            {{ formatDateTime(item.in_progress_date) }}
          </template>
          <template #[`item.delivered_date`]="{ item }">
            {{ formatDateTime(item.delivered_date) }}
          </template>
          <template #[`item.drivers`]="{ item }">
            <div>
              <span
                v-for="(driver, index) in item.drivers"
                :key="driver.id"
              >
                {{ driver.user.fullName }} <b>({{ driver.user.email }})</b><span v-if="index < item.drivers.length - 1">, </span>
              </span>
            </div>
          </template>
          <template #[`item.actions`]="{ item }">
            <v-tooltip
              v-if="item.status === 'NotDelivered' || item.status === 'Planned'"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  medium
                  class="z-green-color mr-2"
                  v-bind="attrs"
                  @click="editItem(item)"
                  v-on="on"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>{{ $t("Common.tooltips.edit") }}</span>
            </v-tooltip>
            <v-tooltip
              v-if="item.status === 'NotDelivered'"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  medium
                  class="z-hover-red-color mr-2"
                  v-bind="attrs"
                  @click="showDeleteModal(item)"
                  v-on="on"
                >
                  delete
                </v-icon>
              </template>
              <span>{{ $t("Common.tooltips.delete") }}</span>
            </v-tooltip>
            <v-tooltip
              v-if="item.status === 'NotDelivered' && !item.is_dispatched"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  color="zwonoyellowdark"
                  medium
                  class="mr-2"
                  v-bind="attrs"
                  @click="mapOrders(item)"
                  v-on="on"
                >
                  mdi-map-marker-plus
                </v-icon>
              </template>
              <span>{{ $t("Common.tooltips.orders") }}</span>
            </v-tooltip>
            <v-tooltip
              v-if="item.status === 'Planned'"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  color="general"
                  medium
                  class="mr-2"
                  v-bind="attrs"
                  @click="showRoute(item)"
                  v-on="on"
                >
                  mdi-router
                </v-icon>
              </template>
              <span>{{ $t("Common.tooltips.route") }}</span>
            </v-tooltip>
            <v-tooltip
              v-if="item.status === 'Delivered'"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  color="zwonogreen"
                  medium
                  class="mr-2"
                  v-bind="attrs"
                  @click="showRouteDelivered(item)"
                  v-on="on"
                >
                  mdi-list-status
                </v-icon>
              </template>
              <span>{{ $t("Common.tooltips.status") }}</span>
            </v-tooltip>
          </template>
          <template #no-data>
            <v-btn
              color="primary"
              @click="initialize"
            >
              Reset
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </material-card>
    <default-popup
      :title="$t('Common.warning')"
      :text="$t('Common.delete_confirmation')"
      :confirm-text="$t('Common.delete_item')"
      :cancel-text="$t('Common.cancel')"
      :alert-text="alertTextDelete"
      :is-visible="isDeleteModalVisible"
      @is-visible="(value) => (isDeleteModalVisible = value)"
      @confirm-btn-click="() => deleteItem()"
    />
    <default-popup
      :title="$t('Common.warning')"
      :text="deleteDeliveriesModalDeliveryStatus === 'NotDelivered' ? $t('Common.remove_order_from_delivery') : $t('Common.remove_order_from_delivery_not_route')"
      :confirm-text="$t('Common.yes')"
      :cancel-text="$t('Common.cancel')"
      :alert-text="alertTextDeleteDeliveries"
      :is-visible="isDeleteDeliveriesModalVisible"
      :is-loading="isDeleteDeliveriesLoading"
      @is-visible="(value) => (isDeleteDeliveriesModalVisible = value)"
      @confirm-btn-click="() => deleteDeliveryData()"
    />
    <change-deal-alternative-address-modal 
      :is-visible="isAlternativeAddressModalVisible"
      :order-id="alternativeAddressModalOrderId"
      :customer-name="alternativeAddressModalCustomerName"
      @is-visible="(value) => changeAlternateAddressVisibility(value)"
      @is-changed="(item) => alternateAddressChanged(item)"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import MapLibre from "@/components/maplibre/MapLibre.vue";
import DefaultPopup from "../../components/DefaultPopup.vue";
import validationMixin from "../../components/helper/validationMixin";
import ChangeDealAlternativeAddressModal from "../../components/modals/ChangeDealAlternativeAddressModal.vue";
import { mapFullAlternativeAddress } from "../../helpers/helpers.js"
import DataTableRowHandler from "./DataTableRowHandler.vue"
import draggable from "vuedraggable"

export default {
  components: {
    MapLibre,
    DefaultPopup,
    ChangeDealAlternativeAddressModal,
    DataTableRowHandler,
    draggable,
  },
  mixins: [validationMixin],
  data: () => ({
    role: "",
    dialog: false,
    dialogMapOrders: false,
    dialogOrderItems: false,
    search: "",
    isDeleteModalVisible: false,
    isDeleteDeliveriesModalVisible: false,
    deleteDeliveriesModalDeliveryStatus: "",
    deleteModalItemId: 0,
    editedIndex: -1,
    editedItem: {
      isValid: true,
      status: "",
      delivery_date: "",
      timeslot_id: "",
      driver_ids: [],
      vehicle_id: "",
      timeslots: [],
    },
    timeslotChanges: [],
    defaultItem: {
      isValid: true,
      status: "",
      delivery_date: "",
      timeslot_id: "",
      driver_ids: [],
      vehicle_id: "",
      timeslots: [],
    },
    mapOrdersIndex: -1,
    mapOrdersItem: {
      deliveryId: -1,
      orders: [],
      firstOrderToDeliverId: "",
    },
    menuDatePicker: false,
    tsSelected: [],
    timeslots_prepared: [],
    dialogShowRoute: false,
    isDialogShowRouteSpinnerActive: false,
    route: {},
    orderDetails: {},
    routeOrders: [],
    selectedFirstOrderToDeliver: "",
    selectedDelivery: null,
    dispatchToWarehouseAlertText: "",
    dispatchToWarehouseAlertType: "success",
    generateDeliveryReportText: "",
    generateDeliveryReportType: "success",
    currentDeliveryId: "",
    mapFromCsvArray: [],
    mapFromCsvNotMapped: [],
    alertTextEdit: "",
    alertTextDelete: "",
    alertTextDeleteDeliveries: "",
    deleteDeliveriesModalVisibleItemId: 0,
    isDeleteDeliveriesLoading: false,
    alertTextMapOrders: "",
    isSaving: false,
    isLoadingButtons: false,
    isDispatching: false,
    isGeneratingReport: false,
    sortedDriverList: null,
    sortedVehicleList: null,
    isAlternativeAddressModalVisible: false,
    alternativeAddressModalOrderId: "",
    alternativeAddressModalCustomerName: "",
    // table:
    paginationLoading: false,
    paginationOptions: {},
    oldSearchValue: '',
  }),

  computed: {
    ...mapGetters("delivery", [
      "deliveryList",
      "deliveryListMeta",
      "generateDeliveryReportStatus",
      "dispatchToWarehouseStatus",
      "mapFromCsvList",
      "deliveryStatusItems",
    ]),
    ...mapGetters("timeslot", ["timeslotList"]),
    ...mapGetters("driver", ["driverList"]),
    ...mapGetters("vehicles", ["vehicleList"]),
    ...mapGetters("order", ["orderList", "ordersForDelivery", "order"]),
    ...mapGetters("endclient", ["endclientStatusItems"]),

    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    deliveryStatus() {
      return this.$store.state.deliveryStatusEnum;
    },
    headers() {
      return [
        { text: this.$t("Delivery.delivery_date"), value: "delivery_date" },
        { text: this.$t("Common.warehouse_status"), value: "warehouse_status" },
        { text: this.$t("Common.status"), value: "status" },
        { text: this.$t("Common.in_progress_date"), value: "in_progress_date" },
        { text: this.$t("Common.delivered_date"), value: "delivered_date" },
        { text: this.$t("Driver.driver_names"), value: "drivers" },
        /* {
          text: this.$t("Vehicle.vehicle_registration"),
          value: "vehicle.vehicle_registration",
        }, */
        { text: this.$t("Common.actions"), value: "actions", sortable: false },
      ];
    },
    headersMapOrders() {
      return [
        { text: this.$t("Order.order_date"), value: "order_date" },
        {
          text: this.$t("Common.customer_name"),
          value: "customer.customer_name",
        },
        { text: this.$t("Common.country"), value: "customer.country" },
        { text: this.$t("Common.city"), value: "customer.city" },
        { text: this.$t("Common.address"), value: "customer.address1" },
        {
          text: this.$t("Common.house_number"),
          value: "customer.street_number",
        },
        { text: this.$t("Common.phone_number"), value: "customer.phone" },
        // { text: this.$t("Common.full_alternative_address"), value: "full_alternative_address" },
        // { text: this.$t("Common.is_user_change"), value: "is_user_change" },
        { text: this.$t("Common.price"), value: "price" },
        { text: this.$t("Order.geo"), value: "customer.is_geocoded" },
        { text: this.$t("Common.actions"), value: "actions",  sortable: false },
      ];
    },
    orderDetailItems() {
      return [
        { text: this.$t("Product.product_name"), value: "product_name" },
        { text: this.$t("Common.qty"), value: "qty" },
        { text: this.$t("Common.price"), value: "price" },
        { text: this.$t("Common.total"), value: "total" },
      ];
    },
    headersRouteOrders() {
      return [
        { text: this.$t("Common.nr"), value: "nr", sortable: false },
        { text: this.$t("Common.timeslot"), value: "timeslot", sortable: false, class: "small-table-column" },
        { text: this.$t("Common.customer_name"), value: "customer_name", sortable: false },
        { text: this.$t("Common.address"), value: "address", sortable: false, class: "large-table-column" },
        { text: this.$t("Common.full_alternative_address"), value: "full_alternative_address", sortable: false, class: "large-table-column" },
        { text: this.$t("Common.is_user_change"), value: "is_user_change", sortable: false, class: "very-small-table-column" },
        { text: this.$t("Order.is_first_to_deliver"), value: "is_first_to_deliver", sortable: false,  class: "very-small-table-column" },
        { text: this.$t("Common.phone_number"), value: "phone", sortable: false, class: "small-table-column" },
        { text: this.$t("Common.email"), value: "email", sortable: false, class: "medium-table-column" },
        {
          text: this.$t("EndClient.endclient_status"),
          value: "status",
          width: "15%",
          sortable: false,
          class: "very-small-table-column"
        },
        {
          text: this.$t("Order.was_reminded"),
          value: "was_reminded",
          sortable: false,
          class: "very-small-table-column"
        },
        { text: this.$t("Common.note"), value: "endclient_message", sortable: false, class: "medium-table-column" },
        {
          text: this.$t("Common.actions"),
          value: "actions",
          filterable: false,
          sortable: false,
          class: "very-small-table-column"
        },
      ];
    },
    headersRouteOrdersDelivered() {
      return [
        { text: this.$t("Common.nr"), value: "nr", width: "10%", sortable: false },
        { text: this.$t("Common.name"), value: "customer_name", sortable: false },
        { text: this.$t("Common.address"), value: "address", sortable: false },
        { text: this.$t("Common.phone"), value: "phone", sortable: false },
        { text: this.$t("Common.email"), value: "email", sortable: false },
        { text: this.$t("Common.status"), value: "status", width: "10%", sortable: false },
        { text: this.$t("Delivery.is_paid"), value: "is_paid", sortable: false },
        { text: this.$t("Common.note"), value: "driver_comment", sortable: false },
        { text: this.$t("Common.actions"), value: "actions",  sortable: false },
      ];
    },
    editedItemFormatedDeliveryDate() {
      return this.formatDate(this.editedItem.delivery_date);
    },

    getAllOrdersForDelivery() {
      const uniqueIds = {};
      return this.mapOrdersItem.orders.concat(this.orderList).filter(obj => {
        if (!uniqueIds[obj.id]) {
          uniqueIds[obj.id] = true;
          return true;
        }
        return false;
      });
    }
  },

  watch: {
    dialog(val) {
      this.alertTextEdit = "";
      this.prepareTimeslots();
      if (val) this.$nextTick(() => {this.$refs.editDeliveryForm.validate()});
      else this.close();
    },
    paginationOptions: {
      handler(val) {
        if(val.search !== this.oldSearchValue) this.paginationOptions.page = 1
        this.oldSearchValue = val.search;
        this.initialize();
      },
      deep: true, // Watch changes deeply in the object
    },
    dialogMapOrders(val) {
      this.alertTextMapOrders = "";
      val || this.closeMapOrders();
    },
    order(val) {
      this.orderDetails = val;
      if (this.orderDetails) {
        this.orderDetails.full_address =
          this.orderDetails.customer.country +
          ", " +
          this.orderDetails.customer.city +
          ", " +
          this.orderDetails.customer.address1 +
          " ";
        this.orderDetails.full_address += [
          this.orderDetails.customer.house_number,
          this.orderDetails.customer.floor_details,
          this.orderDetails.customer.appartment_details,
        ]
          .filter(Boolean)
          .join(" / ");
        this.orderDetails.order_date_formatted = this.formatDate(
          this.orderDetails.order_date
        );
      }
    },
    ordersForDelivery: {
      deep: true,
      handler(val) {
        if (val && val.orders) {
          this.routeOrders = val.orders.map((x) => {
            let obj = {
              nr: x.order_number_in_route ? x.order_number_in_route + 1 :  x.order_number_in_delivery + 1,
              address:
                x.customer.country +
                ", " +
                x.customer.city +
                ", " +
                x.customer.address1 +
                " " +
                [
                  x.customer.house_number,
                  x.customer.floor_details,
                  x.customer.appartment_details,
                ]
                  .filter(Boolean)
                  .join(" / "),
              confirmation_status: x.endclient_status,
              customer_name: x.customer.customer_name,
              phone: x.customer.phone_number,
              email: x.customer.email,
              status: x.status,
              driver_comment: x.driver_comment,
              timeslot: x.timeslot
                ? x.timeslot.start + " - " + x.timeslot.end
                : "",
              endclient_message: x.endclient_special_request
                ? x.endclient_special_request
                : x.endclient_decline_reason,
              is_paid: x.is_paid,
            };
            return { ...x, ...obj };
          });
          this.routeOrders = this.routeOrders.sort((a, b) => a.order_number_in_delivery - b.order_number_in_delivery);
          this.selectedDelivery= val.delivery;
          this.selectedFirstOrderToDeliver = val.delivery?.first_order_to_deliver || ""
        } else {
          this.routeOrders = [];
          this.selectedDelivery = null;
          this.selectedFirstOrderToDeliver = ""
        }
      }
    },
    mapFromCsvList(val) {
      this.mapFromCsvArray = val.mapFromCsvList;
      this.mapOrdersItem.orders = [];
      this.mapFromCsvNotMapped = [];
      if (val && val.mapFromCsvList) {
        for (var item of this.mapFromCsvArray) {
          var source_system_id = item.SourceSystemId;
          // Find order with source_system_id
          let ord = this.orderList.find((x) => {
            return x.source_system_id === source_system_id;
          });
          if (!ord) {
            this.mapFromCsvNotMapped.push(source_system_id);
          } else {
            this.mapOrdersItem.orders.push(ord);
          }
        }
      }
    },
  },

  // lifecycle hooks
  beforeDestroy() {
    this.clearDeliveryState();
    this.clearTimeslotState();
    this.clearOrderState();
    this.clearDriverState();
    this.clearVehicleState();
  },

  methods: {
    ...mapActions("delivery", [
      "getDeliveriesList",
      "createDelivery",
      "updateDelivery",
      "deleteDelivery",
      "dispatchToWarehouse",
      "downloadDispatchPdf",
      "parseCsv",
      "generateDeliveryReport",
      "clearDeliveryState",
      "notifyOrdersEndclientWithoutStatus",
    ]),
    ...mapActions("timeslot", ["getTimeslotList", "clearTimeslotState"]),
    ...mapActions("order", [
      "getOrderedOrdersForCompany",
      "saveOrdersToDelivery",
      "getOrdersForDelivery",
      "getOrder",
      "setAlternativeAddress",
      "clearOrderState",
      "updateOrder",
      "clearDeliveryData",
      "setOrdersOrderNumberInDelivery"
    ]),
    ...mapActions("driver", ["getDriverList", "clearDriverState"]),
    ...mapActions("vehicles", [
      "getVehicleList",
      "clearVehicleState",
    ]),
    ...mapMutations("order", ["changeAlternativeAddressInOrderList", "changeAlternativeAddressInOrdersForDelivery"]),

    async initialize() {
      this.paginationLoading = true;
      this.role = sessionStorage.getItem("role");
      this.paginationOptions.role = sessionStorage.getItem("role");
      await Promise.all([
        this.getDeliveriesList(this.paginationOptions),
        this.getTimeslotList({ itemsPerPage: 'all', role: this.role }),
        this.getDriverList({ itemsPerPage: 'all', role: this.role }),
        this.getOrderedOrdersForCompany(),
        this.getVehicleList({ itemsPerPage: 'all', role: this.role }),
      ]);
      this.sortDriverList();
      this.sortVehicleList();
      this.paginationLoading = false;
    },

    sortDriverList() {
      this.sortedDriverList = this.driverList.sort((a, b) =>
        a.user.firstName.localeCompare(b.user.firstName)
      );
    },

    sortVehicleList() {
      this.sortedVehicleList = this.vehicleList.sort((a, b) =>
        a.vehicle_registration?.localeCompare(b.vehicle_registration)
      );
    },

    prepareTimeslots() {
      if (this.timeslotList) {
        this.timeslots_prepared = this.timeslotList.map((val) => {
          return {
            id: val.id,
            label: val.start + " - " + val.end,
          };
        });
        this.tsSelected = this.timeslotList.map((x) => x.id);
      }
    },

    selectFirstToDeliver(item) {
      if(item.id !== this.selectedFirstOrderToDeliver ) {
        this.selectedFirstOrderToDeliver = item.id
      } else {
        this.selectedFirstOrderToDeliver = ""
      }
    },

    editItem(item) {
      this.editedIndex = this.deliveryList.indexOf(item);
      this.editedItem = Object.assign(item);
      if (this.editedItem?.drivers) {
        this.editedItem.driver_ids = this.editedItem.drivers.map(driver => driver.id);
      }
      if (this.editedItem?.vehicle) {
        this.editedItem.vehicle_id = this.editedItem.vehicle.id;
      }
      const itemTimeslotsCopy = [...item.timeslots]
      this.editedItem.timeslots = this.timeslotList.map((x) => x.id).filter(x => itemTimeslotsCopy.includes(x));
      if (this.editedItem?.delivery_date) {
        this.editedItem.delivery_date = new Date(this.editedItem.delivery_date)
          .toISOString()
          .split("T", 1)[0];
      }
      this.dialog = true;
    },

    async mapOrders(item) {
      this.mapOrdersIndex = this.deliveryList.indexOf(item);
      this.mapOrdersItem = {
        deliveryId: item.id,
        orders: [],
      };
      this.dialogMapOrders = true;
      const ordersForDeliveryRes = await this.getOrdersForDelivery({ delivery_id: item.id });
      if (ordersForDeliveryRes.status === 200) {
        if (ordersForDeliveryRes.data.orders) this.mapOrdersItem.orders = ordersForDeliveryRes.data.orders;
        if (ordersForDeliveryRes.data.deliveryId) this.currentDeliveryId = ordersForDeliveryRes.data.deliveryId;
        if (ordersForDeliveryRes.data.delivery?.first_order_to_deliver) this.mapOrdersItem.firstOrderToDeliverId = ordersForDeliveryRes.data.delivery.first_order_to_deliver
      }
    },

    showDeleteModal(item) {
      this.alertTextDelete = "";
      this.isDeleteModalVisible = true;
      this.deleteModalItemId = item?.id;
    },

    async openAlternateAddressModal(value) {
      await this.getOrder(value.id)
      this.alternativeAddress = value.alternative_address || {};
      this.alternativeAddressModalOrderId = value.id;
      this.alternativeAddressModalCustomerName = value.customer.customer_name;
      this.isAlternativeAddressModalVisible = true;
    },

    changeAlternateAddressVisibility(isVisible) {
      this.isAlternativeAddressModalVisible = isVisible
    },

    async alternateAddressChanged(item) {
      if (this.dialogMapOrders) {
        const orderIndex = this.mapOrdersItem.orders.findIndex( o => o.id === item.orderId )
        this.$set(this.mapOrdersItem.orders[orderIndex], 'alternative_address', item.addressObj);
      }
      const routeOrderIndex = this.routeOrders.findIndex( o => o.id === item.orderId )
      this.$set(this.routeOrders[routeOrderIndex], 'alternative_address', item.addressObj);
      this.changeAlternativeAddressInOrderList({orderId: item.orderId, alternativeAddress: item.addressObj})
    },

    async recalculateDeliveryRoute() {
      this.isDialogShowRouteSpinnerActive = true;
        // this.changeAlternativeAddressInOrdersForDelivery({orderId: item.orderId, alternativeAddress: item.addressObj})
        // Recalculate route
        this.mapOrdersItem = {
          deliveryId: this.selectedDelivery.id,
          orders: this.routeOrders,
          firstOrderToDeliverId: this.selectedFirstOrderToDeliver,
        }

        if(this.timeslotChanges.length) {
          this.mapOrdersItem.orders = this.mapOrdersItem.orders.map( order => {
            const foundTimeslotChange = this.timeslotChanges.find( tChange => tChange.orderId === order.id)
            if (foundTimeslotChange) {
              return {...order, timeslot_change: foundTimeslotChange.timeslot}
            }
            return order
          })
        }
        await this.saveMapOrders(false, false, false)
        this.getOrdersForDelivery({ delivery_id: this.selectedDelivery.id });
        await this.initialize();
        const delivery = this.deliveryList.find( d => d.id === this.selectedDelivery.id)
        if (delivery) this.route = JSON.parse(delivery.route);
        this.isDialogShowRouteSpinnerActive = false;
    },

    async deleteItem() {
      if (this.deleteModalItemId) {
        const isDeleted = await this.deleteDelivery(this.deleteModalItemId);
        if (isDeleted) {
          this.isDeleteModalVisible = false;
        } else {
          this.alertTextDelete = this.$t("Delivery.alerts.error-delete");
        }
      }
    },

    async close() {
      this.dialog = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.resetForm();
    },

    resetForm() {
      this.$refs.editDeliveryForm.resetValidation();
    },

    async closeMapOrders() {
      this.currentDeliveryId = "";
      this.mapFromCsvNotMapped = [];
      this.dialogMapOrders = false;
    },

    async openMapPreview() {
      const isCalculated = await this.saveMapOrders(false, false, false)
      if (isCalculated) {
        this.isLoadingButtons = true;
        await this.getDeliveriesList(this.paginationOptions)
        const deliveryId = this.mapOrdersItem.deliveryId
        const delivery = this.deliveryList.find(d => d.id === deliveryId)
        if (delivery) this.showRoute(delivery)
        this.isLoadingButtons = false;
      }
    },

    async saveMapOrders(notifyEndClient, isSetStatusPlanned, isCloseMapOrders) {
      this.isLoadingButtons = true;
      this.alertTextMapOrders = "";
      if (this.mapOrdersItem?.orders?.some( o => !o.customer?.is_geocoded)) {
        this.alertTextMapOrders = this.$t("Delivery.alerts.error-not-geocoded");
        this.isLoadingButtons = false;
        return false;
      }
      const mapOrdersItemCopy = JSON.parse(JSON.stringify(this.mapOrdersItem))
      const ordersIdsWithTimeslots = mapOrdersItemCopy?.orders?.map( o => { 
        return { order_id: o.id, timeslot_change: o.timeslot_change || null}
      });

      mapOrdersItemCopy.order_ids_with_timeslots = ordersIdsWithTimeslots;
      mapOrdersItemCopy.notify_end_client = notifyEndClient;
      mapOrdersItemCopy.is_set_status_planned = isSetStatusPlanned;
      mapOrdersItemCopy.timeslot_changes = this.timeslotChanges;
      delete mapOrdersItemCopy.orders

      const isUpdated = await this.saveOrdersToDelivery(mapOrdersItemCopy);
      if (isUpdated) {
        if (isCloseMapOrders) {
          this.mapOrdersItem.deliveryId = -1;
          this.mapOrdersItem.orders = [];
          this.mapOrdersItem.firstOrderToDeliverId = "";
          this.closeMapOrders();
        }
        this.initialize();
      } else {
        this.alertTextMapOrders = this.$t("Delivery.alerts.error-delivery");
      }
      this.isLoadingButtons = false;
      return true;
    },

    async save() {
      this.isSaving = true;
      this.alertTextEdit = "";
      if (this.editedIndex > -1) {
        const isUpdated = await this.updateDelivery(this.editedItem);
        if (isUpdated) {
          this.close();
          this.initialize();
        } else {
          this.alertTextEdit = this.$t("Delivery.alerts.error-edit");
        }
      } else {
        this.editedItem.status = "NotDelivered";
        const isCreated = await this.createDelivery(this.editedItem);
        if (isCreated) {
          this.close();
        } else {
          this.alertTextEdit = this.$t("Delivery.alerts.error-create");
        }
      }
      this.isSaving = false;
      this.getOrderedOrdersForCompany(); 
    },
    async showRoute(item) {
      this.dispatchToWarehouseAlertText = "",
      this.generateDeliveryReportText = "",
      this.selectedDelivery = item;
      this.route = JSON.parse(item.route);
      const ordersForDeliveryRes = this.getOrdersForDelivery({ delivery_id: item.id });
      if (ordersForDeliveryRes.data?.delivery?.timeslot_changes) this.timeslotChanges = JSON.parse(ordersForDeliveryRes.data.delivery.timeslot_changes) || [];
      else this.timeslotChanges = [];
      this.dialogShowRoute = true;
    },
    async showRouteDelivered(item) {
      this.dispatchToWarehouseAlertText = "",
      this.generateDeliveryReportText = "",
      this.selectedDelivery = item;
      this.route = JSON.parse(item.route);
      this.dialogShowRoute = true;
      await this.getOrdersForDelivery({
        delivery_id: item.id,
        only_not_declined: true,
      });
    },

    dragStart(event) {
      event.item.classList.add('dragging');
    },
    dragEnd(event) {
      event.item.classList.remove('dragging');
      const oldIndex = event.oldIndex;
      const newIndex = event.newIndex;

      const movedItem = this.routeOrders.splice(oldIndex, 1)[0];
      this.routeOrders.splice(newIndex, 0, movedItem);
      // Update the order_number_in_delivery property for each item to match the new order
      this.routeOrders.forEach((item, index) => {
        item.order_number_in_delivery = index + 1; // Update the order number, assuming it starts from 1
      });

      const ordersChangedDeliveryOrder = this.routeOrders.map( o => { return { id: o.id, order_number_in_delivery: o.order_number_in_delivery}})
      this.setOrdersOrderNumberInDelivery({delivery_id: this.selectedDelivery.id, orders: ordersChangedDeliveryOrder})
    },

    formatDate(value) {
      return value ? new Date(value).toLocaleDateString() : "";
    },

    formatDateTime(value) {
    if (!value) return "";
    const date = new Date(value);
    return date.toLocaleString('default', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
  },

    // Display order items (from map order to delivery dialog)
    async getOrderItems(item) {
      await this.getOrder(item.id);
      this.dialogOrderItems = true;
    },
    closeOrderItems() {
      this.dialogOrderItems = false;
    },
    // END Display order items (from map order to delivery dialog)
    async onDispatchToWarehouse() {
      this.isDispatching = true;
      this.dispatchToWarehouseAlertText = "";
      await this.dispatchToWarehouse(this.selectedDelivery.id);
      if (this.dispatchToWarehouseStatus) {
        this.dispatchToWarehouseAlertText = this.$t(
          "Delivery.dispatch_success"
        );
        this.dispatchToWarehouseAlertType = "success";
        this.selectedDelivery.is_dispatched = true;
      } else {
        this.dispatchToWarehouseAlertText = this.$t("Delivery.dispatch_fail");
        this.dispatchToWarehouseAlertType = "error";
      }
      this.isDispatching = false;
    },
    async onDownloadDispatchPdf() {
      this.isDispatching = true;
      this.dispatchToWarehouseAlertText = "";
      await this.downloadDispatchPdf(this.selectedDelivery.id);
      this.isDispatching = false;
    },
    async onGenerateDeliveryReport() {
      this.isGeneratingReport = true;
      this.generateDeliveryReportText = "";
      await this.generateDeliveryReport(this.selectedDelivery.id);
      if (this.generateDeliveryReportStatus) {
        this.generateDeliveryReportText = this.$t(
          "Delivery.generate_delivery_report_success"
        );
        this.generateDeliveryReportType = "success";
      } else {
        this.generateDeliveryReportText = this.$t(
          "Delivery.generate_delivery_report_fail"
        );
        this.generateDeliveryReportType = "error";
      }
      this.isGeneratingReport = false;
    },

    async onRemindEndclients() {
      this.isDialogShowRouteSpinnerActive = true;
      await this.notifyOrdersEndclientWithoutStatus(this.selectedDelivery.id)
      await this.getOrdersForDelivery({ delivery_id: this.selectedDelivery.id });
      this.isDialogShowRouteSpinnerActive = false;
    },

    selectAllTimeslots() {
      if (this.editedItem?.timeslots?.length > 0) {
        this.editedItem.timeslots = new Array();
      } else {
        var ts = this.timeslotList.map((val) => {
          return val.id;
        });
        this.editedItem.timeslots = ts;
      }
    },
    readFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (res) => {
          resolve(res.target.result);
        };
        reader.onerror = (err) => reject(err);
        reader.readAsText(file);
      });
    },
    async mapFromCsv(file) {
      try {
        if (!file) this.mapFromCsvNotMapped = [];
        const csv = await this.readFile(file);
        const type = "General";
        const delivery_id = this.currentDeliveryId;
        let params = {
          delivery_id: delivery_id,
          type: type,
          csv: csv,
        };
        await this.parseCsv(params);
      } catch (err) {
        alert("There was a problem with loading file!");
      }
    },

    async changeDeliveryStatus(item) {
      if (await this.updateDelivery(item)) {
        this.$root.vtoast.show({
          message: this.$t("Delivery.alerts.status-updated"),
        });
      } else {
        this.$root.vtoast.show({
          message: this.$t("Delivery.alerts.status-not-updated"),
          color: "error",
        });
      }
    },

    async changeEndclientStatus(item) {
      item.endclient_status = item.confirmation_status;
      if (await this.updateOrder(item)) {
        this.$root.vtoast.show({
          message: this.$t("Order.alerts.status-updated"),
        });
      } else {
        this.$root.vtoast.show({
          message: this.$t("Order.alerts.status-not-updated"),
          color: "error",
        });
      }
    },

    buildTimestamp(item) {
      return `${item.start} - ${item.end}`;
    },

    isTimestampChanged(item) {
      return !!this.timeslotChanges.find( t => t.orderId === item.id)
    },

    removeTimestampChange(orderId) {
      this.timeslotChanges = this.timeslotChanges.filter( t => t.orderId !== orderId)
    },

    async clickClearTimestampChange(orderId) {
      this.removeTimestampChange(orderId)
    },

    async changeTimeslot(selectedTimeslot, order ) {
      if(selectedTimeslot) {
        const startAndEndTimeslots = selectedTimeslot.split(' - ')
        const timeslotObj = this.timeslotList.find(
          timeslot => timeslot.start === startAndEndTimeslots[0]
          && timeslot.end === startAndEndTimeslots[1]
        )

        if (timeslotObj?.id) {
            this.removeTimestampChange(order.id);
            this.timeslotChanges.push({ orderId: order.id, timeslot: selectedTimeslot })
        }
      }

      // if (timeslotObj?.id) {
      //   order.timeslot_id = timeslotObj.id
      //   if (await this.updateOrder(order)) {
      //     this.$root.vtoast.show({
      //       message: this.$t("Order.alerts.order-timeslot-updated"),
      //     });
      //   } else {
      //     this.$root.vtoast.show({
      //       message: this.$t("Order.alerts.order-timeslot-not-updated"),
      //       color: "error",
      //     });
      //   }
      // } else {
      //   this.$root.vtoast.show({
      //       message: this.$t('Common.errors.unknown-error'),
      //       color: "error",
      //     });
      // }
    },

    callMapFullAlternativeAddress(addressObj) {
      return mapFullAlternativeAddress(addressObj)
    },
    // clearDeliveryData
    showClearDeliveryDataModal(item) {
      this.alertTextDeleteDeliveries = "";
      this.isDeleteDeliveriesModalVisible = true;
      this.deleteDeliveriesModalVisibleItemId = item?.id;
      this.deleteDeliveriesModalDeliveryStatus = item.delivery.status || ''
    },

    async deleteDeliveryData() {
      this.alertTextDeleteDeliveries = "";
      this.isDeleteDeliveriesLoading = true;
      if (this.deleteDeliveriesModalVisibleItemId) {
        const isDeleted = await this.clearDeliveryData(
          this.deleteDeliveriesModalVisibleItemId
        );
        if (isDeleted) {
          await this.getOrdersForDelivery({ delivery_id: this.selectedDelivery.id });
          await this.removeTimestampChange(this.deleteDeliveriesModalVisibleItemId)
          if (this.deleteDeliveriesModalDeliveryStatus === 'NotDelivered') await this.recalculateDeliveryRoute(); 
          this.isDeleteDeliveriesModalVisible = false;
        } else {
          this.alertTextDeleteDeliveries = this.$t("Order.alerts.error-delete");
        }
      }
      this.isDeleteDeliveriesLoading = false;
    },

    insertWbr(email) {
    return email.replace('@', '<wbr>@');
  }
  },
};
</script>

<style>
.dragging {
  opacity: 0.7;
  cursor: grabbing;
}
</style>
